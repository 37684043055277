@import 'src/utils/styles.scss';

.form {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  > form {
    padding: 0.6rem;
    width: 100%;
    max-width: 25em;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    > input {
      width: 100%;
      padding: 0.5rem;
      margin: 0.4rem;
      display: inline-block;
      border: 1px solid $yellowOchre;
      border-radius: 5px;
      outline: none;
    }

    .form-error {
      padding: 1em;
      background-color: $whiteHoverColor;
      color: $darkRed;
      width: 100%;
      margin: 0.5em 0;
    }

    .submit-button {
      border: none;
      cursor: pointer;
      background-color: $yellowOchre;
    }
  }
}

.form-container {
  height: 100%;
  margin: 4em 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .form-title {
    font-weight: bold;
    font-size: 1.8rem;
    margin: 0.7rem;
  }
}
