@import 'src/utils/styles.scss';

.profile-container {
  button {
    margin: 0.5em 0;
  }

  .owner {
    justify-content: center;
  }

  .info {
    margin: 0.4em 0;
    background-color: $light;
    padding: 0.5em;
  }
}
