@import 'src/utils/styles.scss';

.search-result {
  position: relative;
  min-height: 10em;

  > a {
    display: block;
    width: 95%;
    margin: 1em auto;
  }

  .result-box {
    width: 100%;
    margin: 0.5em;
    border: 1px solid $yellowOchre;

    .result-image {
      width: 35%;
      padding: 0.5em;

      .result-img {
        width: 100%;
        background: antiquewhite;
        height: 100%;

        > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .result-data {
      width: 65%;
      //     display: flex;
      //     flex-direction: column;
      //     padding: 0.3em;

      .result-description-container {
        display: flex;
        padding: 0.5em;
        justify-content: space-between;

        &.mobile {
          display: block;
          padding: 0;
        }

        .result-description {
          padding: 0.2em;

          .result-title {
            font-size: 1.5em;
            font-weight: bold;
          }

          .result-date {
            color: $yellowOchre;
            font-size: 0.8em;
            padding: 0.3em 0;

            .result-issue-number {
              color: $darkYellowOchre;
              font-weight: bold;
            }
          }

          .result-brief-description {
            img {
              display: none;
            }
          }
        }

        .result-opened-by {
          padding: 0.4em;
          margin-top: 0.2em;
          border-left: 1px solid $yellowOchre;
          height: fit-content;

          .result-opener {
            display: flex;
            align-items: center;

            .result-opener-profile-pic {
              width: 1.2em;
              height: 1.2em;
              border-radius: 50%;
              background-color: $whiteHoverColor;
            }

            .result-opener-name {
              padding: 0.2em;
              font-size: 0.8em;
            }
          }

          &.mobile {
            .result-opener {
              display: inline-flex;
            }
          }
        }
      }

      .result-meta-information {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.4em;
        font-size: 0.8em;

        .result-tags-container {
          .result-tag {
            display: inline-block;
            padding: 0.2em 0.5em;
            margin: 0 0.4em;
            border-radius: 0.8em;
            border: 1px solid $dark;
          }
        }

        .result-impressions {
          font-weight: lighter;
          color: $darkRed;

          .result-impression {
            display: inline-block;

            i {
              display: inline-block;
              padding: 0 0.4em;
              font-style: normal;
            }
          }
        }
      }
    }
  }

  .infinite-scroll-loader {
    height: 15em;
    position: relative;

    .no-results,
    .load-more {
      text-align: center;
    }

    button {
      padding: 0.7em 1.2em;
      cursor: pointer;
      border: none;
      outline: none;
      font-size: 0.9em;
      background: $yellowOchre;
      color: $light;
    }
  }
}
