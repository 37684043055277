@import 'src/utils/styles.scss';

.editor-container {
  // padding: 0.8em 1.5em;
  margin: 0.5em 0;
}

.ck {
  &.ck-content {
    &.ck-editor__editable {
      &.ck-rounded-corners {
        &.ck-editor__editable_inline {
          &.ck-blurred,
          &.ck-focused {
            ol,
            ul {
              margin-left: 3em;
            }

            a {
              color: $blue;
            }
          }
        }
      }
    }
  }
}
