@import 'src/utils/styles.scss';

.discussion-home {
  margin: 1em 2em 0;
  display: flex;
  border-top: 1px solid $yellowOchre;

  &.mobile {
    display: block;
    margin: 1em 0;
  }
}
