@import 'src/utils/styles.scss';

.burger {
  flex-grow: 1;
  cursor: pointer;

  .burger-bar {
    width: 30px;
    height: 3px;
    border-radius: 1px;
    background-color: $yellowOchre;
    margin: 0.2em;
  }
}

.nav-links > .nav-link {
  flex-grow: 1;
  margin: 0.2em 0.6em;
  padding: 0.4em 0.8em;
  background-color: $blue;
  border-radius: 20px;
  box-shadow: 0px 0px 11px #9a9cc3;

  a {
    color: $light;
    text-decoration: none;
  }

  button {
    color: $light;
  }
}

.nav-links {
  display: flex;
}

.nav-link {
  a {
    display: block;
  }

  button {
    display: block;
    border: none;
    outline: none;
    background: none;
    font-size: 1em;
    cursor: pointer;
  }
}

.display-nav-links {
  position: absolute;
  top: 2.5em;
  right: 0;
  background-color: #ebdbdb;

  .nav-link {
    padding: 0.4em 0.5em;
    margin: 0.2em;
    border-bottom: 1px solid $dark;

    &:hover {
      background-color: #c2b3b3;
    }
  }
}
