@import 'src/utils/styles.scss';

#root {
  width: 100%;
  height: 100%;
}

.App {
  width: 100%;
  height: 100%;

  .sticky-navbars {
    position: sticky;
    top: 0;
    z-index: 100;
  }

  .no-results-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ul,
  ol {
    margin-left: 3rem;
  }

  blockquote {
    border-left: 3px solid $yellowOchre;
    padding: 0.5rem 0 0.5rem 0.7rem;
    background-color: $whiteHoverColor;
    font-style: italic;
    margin: 0.6rem 0;
  }

  figure {
    overflow-x: auto;
    margin: 0.5em 0;

    table {
      border-collapse: collapse;

      tr {
        &:first-child {
          background-color: $darkYellowOchre;
          color: white;
          font-size: 1.1em;
        }

        td {
          padding: 0.5em;
          border: 1px solid $yellowOchre;
        }
      }
    }
  }

  // img {
  //     width: 100%;
  // }

  .profile-pic-user-circle {
    width: 100%;
    height: 100%;
    color: $dark;
  }

  .profile-pic-user-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
}
