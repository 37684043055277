@import 'src/utils/styles.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Works on Firefox */
* {
  scrollbar-width: 0.2em;
  scrollbar-color: $light $blue;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 0.2em;
  height: 0.2em;
}

*::-webkit-scrollbar-track {
  background: $light;
}

*::-webkit-scrollbar-thumb {
  background-color: $blue;
  border-radius: 1.1em;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  // width: 100vw;
  // overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: $dark;
}

.display-none {
  display: none;
}
