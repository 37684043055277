@import 'src/utils/styles.scss';

.topic-comment-container {
  width: 75%;
  border: 1px solid #77e75b;
  margin: 1em 0;

  &.mobile {
    width: auto;
  }

  .topic-comment-header {
    display: flex;
    justify-content: space-between;
    background-color: $darkYellowOchre;
    color: $light;

    .topic-comment-by {
      padding: 0.3em;
      display: flex;
      align-items: center;

      .topic-comment-by-profile-img {
        width: 1.4em;
        height: 1.4em;
        border-radius: 50%;
        background-color: $whiteHoverColor;
      }

      .topic-comment-by-username {
        padding: 0.3em;

        a {
          color: $light;
        }
      }
    }

    .topic-comment-header-menu {
      padding: 0.5em;
    }
  }

  .topic-comment-description {
    padding: 1em;
    background-color: #fff5f5;

    a {
      color: $blue !important;
    }

    img {
      width: 100%;
    }
  }

  .topic-comment-footer {
    background-color: #f8e6e6;
    display: flex;
    justify-content: space-between;
    padding: 0.4em;
    font-size: 0.8em;

    .topic-comment-footer-impressions {
      display: flex;
      justify-content: space-evenly;
      margin-left: 0.9em;

      .topic-comment-footer-impression {
        padding: 0.2em;
        margin-left: 0.4em;
      }
    }

    .topic-comment-footer-date {
      padding: 0.3em;
      color: $darkYellowOchre;
    }
  }
}
