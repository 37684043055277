@import 'src/utils/styles.scss';

.comments {
  min-height: 10em;
  position: relative;

  .infinite-scroll-comment-loader {
    position: relative;
    height: 6em;

    .load-more {
      text-align: center;

      > button {
        padding: 0.6em 0.8em;
        font-size: 0.9em;
        border: none;
        outline: none;
        background-color: $yellowOchre;
        color: $light;
        cursor: pointer;
      }
    }
  }
}
