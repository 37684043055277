@import 'src/utils/styles.scss';

.blog {
  padding: 0.6em;
  min-height: 15em;
  position: relative;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .blog-title {
    font-size: 2.4em;
    font-weight: bold;
    color: $dark;
    margin: 0.5em 0;
  }

  .blog-writer-date {
    font-size: 0.9em;
    color: $dark;
    margin: 0.5em 0;

    .blog-writer {
      margin: 0.3em 0;
    }
  }

  .blog-content {
    margin: 2em 0;

    img {
      width: 70%;
      height: auto;
      display: block;
      margin: 1em auto 1.4em;
    }
  }

  .blog-impressions {
    margin: 0.6em;

    .blog-impression {
      display: inline-flex;

      .blog-impression-icon {
        margin-right: 0.5em;
      }
    }
  }

  .blog-meta-tags {
    .blog-meta-tag {
      display: inline-block;
      padding: 0.5em 0.8em;
      border: 2px solid $blue;
      border-radius: 0.6em;
      margin-right: 0.6em;
    }
  }

  &.mobile {
    .blog-content {
      img {
        width: 100%;
      }
    }
  }
}
