@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');

.navbar {
  display: flex;
  padding: 0.5em;
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 100;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffffcc;

  .nav-links-container {
    display: flex;
    align-items: center;
    padding: 0.1em;
  }

  .logo {
    font-family: 'Lobster', cursive;
    opacity: 0.7;
    font-size: 1.5em;
    color: darkblue;
    margin-left: 1em;
  }
}
