/**** Light colors ****/
$yellowOchre: #77e75b;
$blue: #4e55fa;
$light: #f3ecec;

/**** Dark colors ****/
$dark: #1c1c1c;
$darkRed: #c54949;
$darkYellowOchre: #4da717;

/**** Hover colors ****/
$whiteHoverColor: #ece6e6;
