@import 'src/utils/styles.scss';

.loader-container {
  // width: 100%;
  // height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f1f1f189;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;

  .loader {
    width: 10em;
    height: 10em;
    border-radius: 50%;
    border: 5px solid #fff;
    border-top: 5px solid $yellowOchre;
    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
