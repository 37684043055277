.blog-list-container {
  .blog-list-title-container {
    padding: 1em;
    text-align: center;
    font-size: 1.6em;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS',
      sans-serif;
  }

  .blog-list {
    display: flex;
    flex-wrap: wrap;
    min-height: 15em;
    position: relative;
  }
}
